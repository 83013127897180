
import TabsComponent from "@/components/navigation/TabsComponent.vue"
import { reactive } from "vue"

export default {
  components: { TabsComponent },
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const tabItems = reactive([
      { label: "Rep Templ", routeName: "reportTemplates" },
      { label: "Rep Templ parts", routeName: "reportTemplatesParts" },
      { label: "Email Templ", routeName: "EmailTemplateSettings" },
      { label: "AQS Templ", routeName: "aqsTemplates" },
    ])

    return {
      tabItems,
    }
  },
}
